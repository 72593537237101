@import "style/resources/variables";

.fixed {
  position: fixed;
  top: 1rem;
  left: 1rem;
}

.appLogo {
  height: 10vh;
  max-height: 4rem;
  fill: $text--light;
}

.appHeader {
  color: $text--light;
  font-size: $h1;

  h1 {
    display: inline;
  }
}

.appNav {
  font-size: 1rem;

  svg {
    height: 0.8em;
    width: 0.8em;
    fill: currentColor;
  }
}

.footerContent {
  padding: $indent;
  font-size: 0.8rem;

  > :not(:last-child) {
    margin-bottom: 1em;
  }

  h2 {
    color: $primary;
  }

  h4 {
    margin-top: 0.5em;
    color: $primary--light;
  }

  .typescript {
    color: $primary--dark;
  }
}

.section {
  min-height: 90vh;
  display: flex;
  padding: 10vh;
}

.sectionWrapper,
.appHeader {
  margin: auto calc(15vw - 4rem);
}

.sectionContent {
  border-left: white 1px solid;
  color: white;
  padding: 0 $indent;
}

.sectionHeader {
  color: $primary;

  small {
    color: $primary--dark;
  }

  h2 {
    display: inline;
  }
}

.header {
  position: relative;

  > :first-child {
    &::before {
      content: "let";
      color: transparentize($text--light, 0.4);
      position: absolute;
      font-size: 0.5em;
      left: -2.5em;
      top: 0.8em;
    }
  }
}

.sectionFooter {
  color: $primary;
  font-size: $h2;
}

.experienceList,
.projectList {
  padding: 0;
}

.h1 {
  font-size: $h1;
}

.h2 {
  font-size: $h2;
}

.h3 {
  font-size: $h3;
  color: $primary--light;
}
