@import 'style/resources/variables';

.listDepth {
  &1, &2, &3 {
    padding: 0 0 0 $indent;
  }

  &1 {
    border-left: white 1px solid;
  }

  &2 {
    border-left: transparentize(white, 0.5) 1px solid;
  }

  &3 {
    border-left: transparentize(white, 0.7) 1px solid;
  }
}