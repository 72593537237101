@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,500,700');
@import './resources/variables';

body {
  margin: 0;
  padding: 0;
  font-family: "Fira Mono", monospace;
  background-color: $secondary;
  color: white;
}

a {
  color: $primary;
  text-decoration: none;

  &:hover {
    color: $primary--light;
  }
}

h1, h2, h3, h4, h5, p, li {
  margin-top: 0;
  &:not(:first-child) {
    margin-top: 0.2rem;
  }
  margin-bottom: 0;
  font-weight: normal;
}

ul {
  margin: 0.2rem 0;
  padding: 0 $indent;
  list-style: none;
}

h1 {
  font-size: $h1;
}

h2 {
  size: $h2;
}

h3 {
  size: $h3;
  color: $primary--light;
}

h5 {
  font-size: smaller;
}

small {
  opacity: 0.7;
  font-size: 1em;
}

main {
  > :nth-child(2n + 1) {
    background-color: $secondary--dark;
  }
}
