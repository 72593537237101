$primary: #ff6d00;
$primary--light: #ff9e40;
$primary--dark: #c43c00;

$secondary: #212121;
$secondary--light: #484848;
$secondary--dark: #000;

$text--dark: #000;
$text--light: #fff;

$h1: 2rem;
$h2: 1.5rem;
$h3: 1.2rem;
$indent: 1.5rem;